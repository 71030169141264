export const EntitiesName = Object.freeze({
  Agency: "agency",
  Attachement: "attachement",
  BpuSst: "bpu_sst",
  BpuTemplate: "bpu_template",
  Category: "category",
  Company: "company",
  Contract: "contract",
  ContractTemplate: "contract_template",
  Dc4: "budget_dc4",
  Deposit: "deposit",
  Document: "document",
  DocumentType: "document_type",
  Dsp: "dsp",
  Envelope: "envelope",
  Imputation: "imputation",
  MarketSst: "market_sst",
  MonthlyStatement: "monthly_statement",
  Notification: "notification",
  Nro: "nro",
  Od: "budget_od",
  Order: "order",
  OscarProject: "oscar_project",
  OscarSubProject: "oscar_subproject",
  OsTemplate: "os_template",
  PaymentGuarantee: "payment-guarantee",
  Pm: "pm",
  PmProject: "pm_project",
  Popup: "popup",
  Prestation: "prestation",
  PrestationReference: "prestation_reference",
  PrestationType: "prestation_type",
  Project: "project",
  ProjectType: "project_type",
  Quantitative: "budget_quantitative",
  Role: "role",
  SubcontractorMarket: "subcontractor_market",
  Unit: "unit",
  User: "user",
  UserAgency: "user_agency",
  UserProject: "user_project",
  Warranties: "budget_rg_caution",
  Evaluation: "evaluation",
});

export const Entities = [];
