<template>
  <div class="header">
    <slot></slot>

    <i v-if="loading"
       cy-data="loader"
       class="loader inline"></i>

    <div class="is-pulled-right">
      <slot name="actions"></slot>
      <button v-if="create && getRights(entity).create"
              class="button is-primary"
              :cy-data="`cy-${entity}-create`"
              :data-test="`sel-page-${entity}-create`"
              @click="create">{{$t('common.create')}}</button>
      <button v-if="edit && !editMode && getRights(entity).update"
              class="button is-primary"
              :cy-data="`cy-${entity}-edit`"
              :data-test="`sel-page-${entity}-edit`"
              @click="edit">{{$t('common.edition')}}</button>
      <button v-if="cancel && editMode"
              class="button is-danger"
              :cy-data="`cy-${entity}-cancel`"
              :data-test="`sel-page-${entity}-cancel`"
              @click="cancel">{{$t('common.cancel')}}</button>
      <button v-if="save && editMode && getRights(entity).update"
              :cy-data="`cy-${entity}-save`"
              class="button is-success"
              :class="{'is-loading': isLoading}"
              @click="triggerSave">{{$t('common.save')}}</button>
      <button v-if="remove && getRights(entity).delete"
              :cy-data="`cy-${entity}-delete`"
              :data-test="`sel-page-${entity}-delete`"
              class="button is-danger"
              @click="remove">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'page-header',
  props: {
    entity: String,
    create: Function,
    edit: Function,
    cancel: Function,
    save: Function,
    remove: Function,
  },
  data () {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'states/isEdition',
      getRights: 'auth/getRights',
      loading: 'states/isLoading',
    }),
  },
  methods: {
    triggerSave () {
      this.isLoading = true;

      const p = this.save();

      if (p) {
        p.finally(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.inline {
  display: inline-block;
  border-left-color: #0195b7;
  border-bottom-color: #0195b7;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .loader {
    margin-right: auto;
    margin-left: 10px;
  }
  .is-pulled-right > * {
    margin-left: 4px;
  }
}
</style>
