<template>
  <modal
    ref="addEnvelopeModal"
    @create="handleAddEnvelope"
    @close="resetForm"
    :title="$t('envelopes.addEnvelope')"
    :disabled="isDisabled"
  >
    <div class="columns">
      <div class="column is-half">
        <text-field
          :label="$t('common.name')"
          v-model="newEnvelope.name"
          :edit="true"
          :inline="false"
          :error="errors.has('name')"
        />
        <p v-if="errors.has('name')" class="help is-danger">
          {{ errors.first("name") }}
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
export default {
  name: "AddEnvelopeModal",
  inject: ["$validator"],
  props: {
    contractId: [String, Number],
  },
  data() {
    return {
      newEnvelope: {
        name: null,
      },
      newEnvelopeId: null,
    };
  },
  computed: {
    isDisabled() {
      return !this.newEnvelope.name;
    },
  },
  methods: {
    open() {
      this.resetForm();
      this.$openModal("addEnvelopeModal");
    },
    close() {
      this.$closeModal("addEnvelopeModal");
    },
    async handleAddEnvelope() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        const envelope = {
          ...this.newEnvelope,
          contractId: this.contractId,
        };
        try {
          const response = await axios.post(`/envelope`, envelope);
          this.newEnvelopeId = response.data.id;
          this.$emit("created", this.newEnvelopeId);
          this.close();
        } catch (error) {
          console.error("Error adding envelope:", error);
          this.$awn.error(this.$t("expressions.addEnvelopeError"));
        }
      }
    },
    resetForm() {
      this.newEnvelope.name = null;
      this.errors.clear();
    },
  },
};
</script>
