<template>
  <div>
    <div class="evaluation-container">
      <div>
        <button
          v-if="
            getRights($entitiesName.Evaluation).create &&
            userRoleOptions.includes($roleOptions.EVALUATIONS.EVALUATE_SAFETY)
          "
          class="button is-success is-pulled-right is-small m-l-20"
          @click.stop="$refs.addEvaluationModal.open()"
          @click="setEvaluationType('safety')"
        >
          <i class="fa fa-plus"></i>
          &nbsp;{{ $t("evaluation.safetyTitle") }}
        </button>
        <button
          v-if="
            getRights($entitiesName.Evaluation).create &&
            userRoleOptions.includes(
              $roleOptions.EVALUATIONS.EVALUATE_PROCUREMENT
            )
          "
          class="button is-success is-pulled-right is-small"
          @click.stop="$refs.addEvaluationModal.open()"
          @click="setEvaluationType('procurement')"
        >
          <i class="fa fa-plus"></i>
          &nbsp;{{ $t("evaluation.procurementTitle") }}
        </button>
      </div>
    </div>
    <datatable
      :fetch="fetchEvaluationPerAgency"
      key="agencyEvaluation"
      ref="agencyEvaluation"
      objectName="agencyEvaluation"
      :enableDownload="false"
      :size="10"
      :noSelection="true"
      :isSmall="true"
      :reversePagination="true"
      :singleSelect="true"
      :filterColumns="false"
      :paginateOptions="[10, 20, 50]"
    >
      <div
        slot="type"
        slot-scope="{ item }"
        :title="$t('evaluation.type')"
        :sortable="true"
      >
        {{ getTypeTranslation(item.type) }}
      </div>
      <div
        slot="value"
        slot-scope="{ item }"
        :title="$t('evaluation.evaluation')"
        :sortable="true"
      >
        {{ item.value }}
      </div>
      <div
        slot="comment"
        slot-scope="{ item }"
        :title="$t('evaluation.comment')"
        :sortable="true"
      >
        {{ item.comment }}
      </div>
      <div
        slot="document"
        slot-scope="{ item }"
        :title="$t('evaluation.document')"
        :sortable="true"
      >
        <div class="document-container">
          <i
            v-if="item.documents && item.documents.length > 0"
            class="fa fa-cloud-download"
            @mouseenter="showPopup(item.id)"
            @mouseleave="hidePopup(item.id)"
            @click="downloadAllDocuments(item.documents)"
          ></i>

          <div v-if="isPopupVisible(item.id)" class="filename-popup">
            <ul>
              <li v-for="(doc, index) in item.documents" :key="index">
                {{ doc.filename }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </datatable>
    <modal
      @create="addEvaluation"
      ref="addEvaluationModal"
      :title="$t(`evaluation.${this.evaluationType}Title`)"
    >
      <div class="columns">
        <div class="column" style="height: 650px">
          <star-rating
            :label="$t('evaluation.evaluation')"
            :number-of-stars="10"
            @rating-selected="handleRating"
          ></star-rating>

          <textarea-field
            class="m-t-15"
            :label="$t('evaluation.comment')"
            :inline="false"
            :edit="true"
            v-model="evaluation.comment"
          ></textarea-field>

          <div class="multi-upload-container">
            <div class="document-label">Documents</div>
            <vue-dropzone
              ref="vueDropzone"
              :options="dropzoneOptions"
              @vdropzone-file-added="handleFileAdded"
              @vdropzone-file-removed="handleFileRemoved"
              id="customdropzone"
              :include-styling="false"
              :useCustomSlot="true"
            >
              <div class="default-message" v-if="selectedFiles.length === 0">
                <i class="fa fa-cloud-upload"></i>Déposer ou faire glisser
              </div>
            </vue-dropzone>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "evaluation-agency",
  components: {
    VueDropzone,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    const dictRemoveFileText =
      "<i style='margin-top: -25px; margin-left: -35px' class='delete is-pulled-left is-medium'></i>";
    return {
      evaluation: {
        value: 0,
        comment: "",
      },
      evaluationType: "",
      selectedFiles: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: "http://localhost",
        maxFilesize: 2,
        addRemoveLinks: true,
        dictRemoveFile: dictRemoveFileText,
        dictDefaultMessage: "",
        previewTemplate: this.template(),

        removedfile: (file) => {
          this.handleFileRemoved(file);
        },
      },

      createDocument: {
        documentType: "evaluation",
        file: null,
      },
      visiblePopups: {},
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),
  },
  methods: {
    template() {
      return `<div style='position: relative; width: fit-content; margin-bottom: 15px; text-wrap: nowrap; margin-left: 25px' class="dz-preview dz-file-preview">
                <div class="dz-details">
                  <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-error-message">
                  <span data-dz-errormessage></span>
                </div>
              </div>`;
    },

    addEvaluation() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios
            .post(
              `/evaluation/${this.evaluationType}/${this.id}`,
              this.evaluation
            )
            .then((response) => {
              if (this.selectedFiles.length > 0) {
                this.uploadFiles(response.data.id);
              }
            })
            .finally(() => {
              this.evaluation.value = 0;
              this.evaluation.comment = "";
              this.selectedFiles = [];
              this.$refs.vueDropzone.removeAllFiles();
              this.$refs.addEvaluationModal.close();
              this.$refs.agencyEvaluation.refresh();
            });
        }
      });
    },
    handleRating(rating) {
      this.evaluation.value = rating;
    },
    setEvaluationType(params) {
      this.evaluationType = params;
    },
    fetchEvaluationPerAgency(p) {
      return this.$api.fetchEvaluationPerAgency(p, this.id);
    },
    getTypeTranslation(type) {
      switch (type) {
        case 1:
          return this.$t("evaluation.safety");
        case 2:
          return this.$t("evaluation.procurement");
        case 3:
          return this.$t("evaluation.project");
        default:
          return this.$t("evaluation.none");
      }
    },

    handleFileAdded(file) {
      this.selectedFiles.push(file);
      this.$nextTick(() => {
        const dzMessage =
          this.$refs.vueDropzone.$el.querySelector(".dz-message");
        if (dzMessage) {
          dzMessage.remove();
        }
      });
    },

    handleFileRemoved(file) {
      this.selectedFiles = this.selectedFiles.filter(
        (selectedFile) =>
          selectedFile.name !== file.name || selectedFile.size !== file.size
      );

      if (file.previewElement) {
        file.previewElement.remove();
      }
    },

    uploadFiles(evaluationId) {
      this.selectedFiles.forEach((i) => {
        this.createDocument.file = i;

        const form = new FormData();
        form.set(
          "file",
          this.createDocument.file,
          this.createDocument.file.name
        );
        form.set("entity", "evaluation");
        form.set("id", evaluationId);
        form.set("type", 11);
        axios.post("/document", form).then(() => {
          this.$refs.addEvaluationModal.close();
        });
      });
    },
    showPopup(itemId) {
      this.$set(this.visiblePopups, itemId, true);
    },
    hidePopup(itemId) {
      this.$set(this.visiblePopups, itemId, false);
    },
    isPopupVisible(itemId) {
      return !!this.visiblePopups[itemId];
    },

    downloadAllDocuments(documents) {
      documents.forEach((doc) => {
        const link = document.createElement("a");
        link.href = doc.path;
        link.download = doc.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.m-l-20 {
  margin-left: 20px;
}
.m-t-15 {
  margin-top: 15px;
}
.multi-upload-container {
  width: 500px;
}

.document-label {
  font-weight: bold;
  vertical-align: top;
  padding-right: 4px;
}

#customdropzone {
  background-color: white;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: auto;
  padding: 40px;
  margin-top: 5px;
  border-style: dashed;
  border-color: #019fc4;
}

#customdropzone:hover {
  background-color: #f6f6f6;
}

.default-message {
  cursor: pointer;
  text-align: center;
  margin: 2em 0;
  letter-spacing: 0.2px;
  color: #777;
}

.document-container {
  position: relative;
  display: inline-block;
}

.filename-popup {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  border-radius: 5px;
}

.filename-popup::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.filename-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filename-popup li {
  margin-bottom: 5px;
}

.filename-popup li:last-child {
  margin-bottom: 0;
}
</style>
