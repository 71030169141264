<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Contract"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" cy-data="cy-contract-title">
          <breadcrumb
            location="/sst/contracts"
            :text="$t('entities.contract')"
          ></breadcrumb>
          {{ contract.contractNumber }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="tab-container has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("common.envelopes") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                @click="currentTab = 2"
              >
                <a>{{ $t("common.contractorAgreement") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 0">
          <text-field
            cy-data="contractNumber"
            :label="$t('contract.contractNumber')"
            v-model="contract.contractNumber"
            required
          ></text-field>

          <checkbox-field
            :value="contract.active"
            v-model="contract.active"
            :label="$t('contract.active')"
            :inline="true"
          />
          <!--          <field v-if="!editMode" :edit="false" :label="$t('contract.active')" isCheckbox :isChecked="contract.active" />-->

          <many2one-field
            :label="$t('contract.parentContract')"
            v-model="contract.parent"
            :fetch="fetchPotentialParents"
            :columns="columns.parent"
            reference="contractNumber"
            @click="(item) => $router.push('/sst/contract/' + item.id)"
            :inline="true"
            :hasFilter="true"
          ></many2one-field>

          <many2one-field
            :label="$t('contract.contractType')"
            v-model="contract.documentType"
            :fetch="$api.fetchDocumentTypes"
            reference="name"
            @click="(item) => $router.push('/documenttype/' + item.id)"
            :columns="columns.documentType"
            :inline="true"
            :hasFilter="true"
            required
            v-validate="{ required: true }"
            name="documentType2one"
            :error="errors.has('documentType2one')"
          ></many2one-field>

          <many2one-field
            :label="$t('common.agency')"
            v-model="contract.agency"
            :fetch="$api.fetchAgencies"
            reference="edifice"
            @click="(item) => $router.push('/sst/agency/' + item.id)"
            :columns="columns.agency"
            :inline="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="agency2one"
            :error="errors.has('agency2one')"
          ></many2one-field>

          <date-field
            :label="$t('contract.signatureDate')"
            v-model="contract.signatureDate"
            :inline="true"
            required
            v-validate="{ required: true }"
            name="signatureDate"
            :error="errors.has('signatureDate')"
          ></date-field>

          <date-field
            :label="$t('contract.startDate')"
            v-model="contract.startDate"
            :inline="true"
            required
            v-validate="{ required: true }"
            name="startDate"
            :error="errors.has('startDate')"
          ></date-field>

          <date-field
            :label="$t('contract.endDate')"
            v-model="contract.endDate"
            :inline="true"
            required
            v-validate="{ required: true }"
            name="endDate"
            :error="errors.has('endDate')"
          ></date-field>

          <text-field
            type="number"
            :label="$t('contract.duration')"
            v-model="contract.duration"
            :inline="true"
            v-validate="{ required: true }"
            name="duration"
            :error="errors.has('duration')"
          ></text-field>

          <date-field
            :label="$t('contract.terminationDate')"
            v-model="contract.terminationDate"
            :inline="true"
            name="terminationDate"
          ></date-field>

          <date-field
            :label="$t('contract.expirationDateAfterRenewal')"
            v-model="contract.expirationDateAfterRenewal"
            :inline="true"
            name="expirationDateAfterRenewal"
          ></date-field>

          <many2one-field
            :label="$t('contract.referent')"
            v-model="contract.referent"
            :fetch="$api.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/user/' + item.id)"
            :columns="columns.user"
            :hasFilter="true"
            :inline="true"
            required
            v-validate="{ required: true }"
            :error="errors.has('referent')"
            data-test="sel-os-adminReferent"
          ></many2one-field>

          <many2one-field
            :label="$t('contract.adminReferent')"
            v-model="contract.adminReferent"
            :fetch="$api.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/user/' + item.id)"
            :columns="columns.user"
            :hasFilter="true"
            :inline="true"
            required
            v-validate="{ required: true }"
            :error="errors.has('adminReferent')"
            data-test="sel-os-adminReferent"
          ></many2one-field>

          <textarea-field
            :label="$t('common.comments')"
            v-model="contract.comment"
            :inline="true"
          />
        </div>

        <div class="panel-block" v-if="currentTab === 1">
          <envelopes-manager
            :contractId="id"
            :signature="
              userRoleOptions.includes(
                $roleOptions.ENVELOPES.CAN_SEND_FOR_SIGNATURE
              )
            "
          />
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 2">
          <flexi-table
            class="is-full-width"
            :loader="fetchMarketForContract"
            :columns="columns.market"
            :showIndex="true"
            ref="marketTable"
          >
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.edifice }}</td>
                <td>{{ item.project && item.project.name }}</td>
                <td>
                  <restricted-link
                    :entity="$entitiesName.SubcontractorMarket"
                    :to="'/sst/market/' + item.id"
                    linkClass="button is-small is-primary"
                  >
                    <i class="fa fa-search"></i>
                  </restricted-link>
                </td>
              </tr>
            </template>
          </flexi-table>
        </div>
      </div>

      <div class="columns" v-if="currentTab === 0">
        <div class="column">
          <div class="panel">
            <div class="panel-heading has-background-primary">
              {{ $t("contract.contractChild") }}
            </div>
            <div class="panel-block">
              <div
                class="has-text-centered is-full-width"
                v-if="childContracts.length <= 0"
              >
                {{ $t("contract.noChildContracts") }}
              </div>
              <table class="table is-fullwidth" v-else>
                <thead>
                  <tr>
                    <td>{{ $t("contract.contractNumber") }}</td>
                    <td>{{ $t("contract.active") }}</td>
                    <td>{{ $t("contract.contractType") }}</td>
                    <td>{{ $t("common.agency") }}</td>
                    <td>{{ $t("contract.signatureDate") }}</td>
                    <td>{{ $t("contract.startDate") }}</td>
                    <td>{{ $t("contract.endDate") }}</td>
                    <td>{{ $t("contract.duration") }}</td>
                    <td>{{ $t("contract.terminationDate") }}</td>
                    <td>{{ $t("contract.expirationDateAfterRenewal") }}</td>
                    <td>{{ $t("contract.referent") }}</td>
                    <td>{{ $t("contract.adminReferent") }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(c, index) in childContracts" :key="index">
                    <td>{{ c.contractNumber }}</td>
                    <td>
                      <field isCheckbox :isChecked="c.active" />
                    </td>
                    <td>{{ c.documentType && c.documentType.name }}</td>
                    <td>{{ c.agency && c.agency.edifice }}</td>
                    <td>{{ c.signatureDate | date }}</td>
                    <td>{{ c.startDate | date }}</td>
                    <td>{{ c.endDate | date }}</td>
                    <td>{{ c.duration }}</td>
                    <td>{{ c.terminationDate | date }}</td>
                    <td>{{ c.expirationDateAfterRenewal | date }}</td>
                    <td>{{ c.referent && c.referent.name }}</td>
                    <td>{{ c.adminReferent && c.adminReferent.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "contract",
  props: ["id"],
  watch: {
    id() {
      this.fetchData();
    },
  },
  data() {
    return {
      contract: {},
      childContracts: [],
      columns: {
        agency: {
          "company.name": this.$t("common.company"),
          edifice: this.$t("common.agency"),
          siret: this.$t("common.siret"),
          manager: this.$t("common.manager"),
        },
        documentType: {
          name: this.$t("common.name"),
          code: this.$t("common.code"),
        },
        user: {
          name: this.$t("common.name"),
          mail: "E-Mail",
          "role.name": this.$t("entities.role"),
          service: this.$t("common.service"),
        },
        parent: {
          "agency.edifice": this.$t("common.agency"),
          contractNumber: this.$t("contract.contractNumber"),
          "documentType.name": this.$t("contract.documentType"),
          "referent.name": this.$t("contract.referent"),
          "adminReferent.name": this.$t("contract.adminReferent"),
        },
        market: [
          {
            title: this.$t("common.edifice"),
            name: "edifice",
            accessor: "edifice",
          },
          {
            title: this.$t("common.project"),
            name: "project",
            accessor: "project.name",
          },
          {
            title: "",
            name: "id",
            accessor: "id",
          },
        ],
      },
      currentTab: 0,
      envelopeId: null,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),
  },
  mounted() {
    this.fetchData();
    this.envelopeId = this.$route.query.envelopeId;
    if (this.envelopeId) {
      this.currentTab = 1;
    }
  },
  methods: {
    fetchData() {
      axios.get(`/contract/${this.id}`).then((response) => {
        this.contract = response.data;
      });
      axios.get(`/contract/${this.id}/childs`).then((response) => {
        this.childContracts = response.data;
      });
    },
    fetchPotentialParents(p) {
      return this.$api.fetchPotentialParents(p, this.id);
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return Promise.resolve();
        }

        return axios
          .put(`/contract/${this.id}`, this.contract)
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch("states/setEdition", false);
            }
          })
          .finally(() => {
            this.fetchData();
          });
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      axios.get(`/contract/${this.id}`).then((response) => {
        this.contract = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    remove() {
      return this.$awn.confirm(this.$t("expressions.deleteContract"), () =>
        axios.delete(`/contract/${this.id}`).then((response) => {
          if (response.data.success) {
            this.$router.replace("/sst/contracts");
          }
        })
      );
    },
    fetchMarketForContract(callback) {
      return this.$api.fetchMarketForContract(this.id).then((response) => {
        if (typeof callback === "function") {
          callback(response);
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.tab-container {
  padding: 0em 0.75em;
  font-weight: bold;

  .tabs {
    ul {
      border-bottom: none;
    }

    li {
      width: 25%;

      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }

      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.panel-block {
  position: relative;
}

.is-full-width {
  flex: 1;
  width: 100%;
}
</style>
