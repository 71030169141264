const common = {
  all: "Tous",
  action: "Action",
  active: "Actif",
  add: "Ajouter",
  address: "Adresse",
  agencies: "Etablissements",
  agency: "Etablissement",
  attachment: "Attachement",
  attachments: "Attachements",
  amount: "Montant",
  bpu: "BPU",
  bpuTemplates: "BPU Templates",
  cancel: "Annuler",
  category: "Catégorie",
  close: "Fermer",
  code: "Code",
  city: "Commune",
  comments: "Commentaires",
  company: "Société",
  connection: "Connexion",
  contactInformation: "Coordonnées",
  contractor: "Sous-traitant",
  contractorAgreement: "Marché Sous-Traitant",
  create: "Créer",
  createDelegation: "Créer une délégation",
  creation: "Création",
  creator: "Créateur",
  currency: "Devise",
  currentCurrency: "EUR",
  date: "Date",
  dashboard: "Dashboard",
  deletion: "Suppression",
  deposits: "Cautions",
  depositType: "Dépôt",
  description: "Description",
  designation: "Désignation",
  disconnect: "Déconnexion",
  accountManage: "Gérer le compte",
  dgd: "Projet de Décompte",
  dsp: "DSP",
  director: "Dirigeant",
  display: "Affichage",
  documents: "Documents",
  download: "Télécharger",
  edifice: "Référence interne contrat",
  edition: "Édition",
  elements: "Éléments",
  email: "E-Mail",
  envelopes: "Enveloppes",
  eotp: "eOTP",
  eur: "EUR",
  exporter: "Exporter",
  generate: "Générer",
  help: "Aide",
  id: "Id",
  import: "Importer",
  imputation: "Imputation",
  imputations: "Imputations",
  information: "Informations",
  mail: "Mail",
  manager: "Responsable",
  menu: "Menu",
  milestone: "Jalon",
  name: "Nom",
  next: "Suivant",
  node: "Noeud",
  nro: "NRO",
  object: "Objet",
  objects: "Objets",
  observation: "Observation",
  on: "sur",
  onDate: "le",
  options: "Options",
  order: "Commande",
  orders: "Commandes",
  payment: "Paiement",
  permissions: "Permissions",
  phone: "Téléphone",
  pm: "PM",
  prestations: "Prestations",
  previous: "Précédent",
  profile: "Profil",
  project: "Projet",
  projects: "Projets",
  quality: "Qualité",
  quantity: "Quantité",
  quantitatives: "Quantitatifs",
  rad: "RAD",
  reference: "Référence",
  responsible: "Responsable",
  retention: "Retenue de garantie",
  retentions: "Retenues de garantie",
  rg: "RG",
  role: "Rôle",
  sap: "SAP",
  save: "Sauvegarder",
  selection: "Sélection",
  send: "Envoyer",
  service: "Service",
  siren: "SIREN",
  siret: "SIRET",
  status: "Statut",
  subcategory: "Sous-catégorie",
  template: "Template",
  to: "à",
  total: "Total",
  transitions: "Transitions",
  type: "Type",
  unit: "Unité",
  upload: "Téléverser",
  users: "Utilisateurs",
  vat: "TVA",
  validate: "Valider",
  validator: "Validateur",
  visibility: "Visibilité",
  workflow: "Workflow",
  contracts: "Contrats",
  popups: "Popups",
  preview: "Aperçu",
  delete: "Supprimer",
  evaluation: "Évaluation",
  noRecords: "Pas d'enregistrements",
  phoneCountryCode: "Indicatif Téléphonique",
};

const expressions = {
  accessNotGranted:
    "Vous n'avez pas les droits necessaires pour accéder à cette page.",
  addDocument: "Ajout d'un document",
  addedOn: "Ajoutée le",
  contractName: "Nom du contrat",
  createdAt: "Créé le",
  creationDate: "Date de création",
  delete: "Êtes-vous sûr de vouloir supprimer cet élément ?",
  deleteDocument: "Êtes-vous sûr de vouloir supprimer ce document ?",
  deleteContract:
    "Voulez-vous vraiment supprimer ce contrat et ses enveloppes, documents, validateurs et historiques de flux de travail associés ?",
  deleteEnvelope:
    "Voulez-vous vraiment supprimer cette enveloppe et ses documents, validateurs et historiques de flux de travail associés ?",
  endDate: "Date de fin",
  fetchingError: "La récupération des données n'a pas fonctionné",
  fileName: "Nom de fichier",
  keepProfile: `Vous êtes connecté sur un autre onglet en tant que {0}, souhaitez vous continuer avec ce profil ?`,
  leaveWithoutSaving:
    "Êtes-vous sûr de vouloir quitter la page sans enregistrer ?",
  mustBeAfter: "La date doit être supérieure à celle de la situation mensuelle",
  myTasks: "Mes actions",
  myScope: "Mon périmètre",
  noAccessToEntity: "Vous n'avez pas les droits d'accès à cette entité.",
  noReadAccessToEntity:
    "Vous n'avez pas les droits en lectue pour accéder à cette entité.",
  noAttachment: "Aucun attachement",
  noData: "Aucune donnée à afficher",
  noDocumentSelected: "Aucun document sélectionné",
  noFileSelected: "Vous n'avez sélectionné aucun fichier",
  noDocumentTypleSelected: "Vous n'avez sélectionné aucun type de document",
  noPrestation: "Aucune prestation",
  noUser: "Aucun utilisateur",
  problemDescription: "Description du problème",
  projectName: "Nom du projet",
  projectConcerned: "Projet de rattachement",
  selectFile: "Sélectionner un fichier",
  selectAttachment: "Sélectionner une pièce jointe",
  selectedAttachmentName: "Nom de la pièce jointe sélectionnée",
  selectedFileName: "Nom du fichier sélectionné",
  startDate: "Date de début",
  templateName: "Nom du modèle",
  reportIncidentToSupport: "Signaler un problème au support",
  reportIncident: "Déclarer un incident",
  triggeredBy: "Mis dans ce statut par",
  userManuals: "Modes opératoires",
  deactivate: "Êtes-vous sûr de vouloir désactiver cet élément ?",
  activate: "Êtes-vous sûr de vouloir activer cet élément ?",
  validationDate: "Date de validation",
};

const entities = {
  agency: "Etablissement",
  agencies: "Etablissements",
  attachment: "Attachement",
  attachments: "Attachements",
  bpu: "BPU",
  bpus: "BPUs",
  bpuTemplate: "BPU Template",
  bpuTemplates: "BPU Templates",
  company: "Société",
  companies: "Sociétés",
  contractorAgreement: "Marché Sous-traitant",
  contractorAgreements: "Marchés Sous-traitant",
  contractTemplate: "Modèle de contrat",
  contractTemplates: "Modèles de contrat",
  documentType: "Type de document",
  documentTypes: "Types de document",
  notifications: "Notifications",
  oscarProject: "Projet OSCAR",
  oscarProjects: "Projets OSCAR",
  oscarSubProject: "Sous-projet OSCAR",
  oscarSubProjects: "Sous-projets OSCAR",
  order: "Commande",
  orders: "Commandes",
  orderTemplate: "Modèle de commande",
  orderTemplates: "Modèles de commande",
  paymentGuarantee: "Garantie de Paiement",
  paymentGuarantees: "Garanties de Paiement",
  prestation: "Prestation",
  prestations: "Prestations",
  prestationReference: "Référence de prestation",
  prestationReferences: "Références de prestation",
  prestationType: "Type de prestation",
  prestationTypes: "Types de prestation",
  projectType: "Type de projet",
  projectTypes: "Types de projet",
  role: "Rôle",
  roles: "Rôles",
  situation: "Situation mensuelle",
  situations: "Situations mensuelles",
  unit: "Unité",
  units: "Unités",
  user: "Utilisateur",
  users: "Utilisateurs",
  workflowNode: "Noeud de workflow",
  contract: "Contrat",
  popups: "Popups",
};

const login = {
  title: "Interface de Suivi Financier",
  username: "Identifiant",
  password: "Mot de passe",
  forgotPassword: "Mot de passe oublié ?",
  connexion: "Connexion",
};

const home = {
  nothingToValidate: "Aucune commande ou attachement à valider.",
  myOrders: "Mes commandes à traiter",
  myAttachments: "Mes attachements à traiter",
  workflowProcess: "Workflows à traiter",
  ongoingWork: "Travaux en cours",
  ongoingOrders: "Commandes en cours de",
};

const agencies = {
  addUser: "Ajouter un utilisateur",
  agencyCreation: "Création d'un etablissement",
  anomalies:
    "L'établissement présente une anomalie administrative (soit un dossier incomplet, soit fait l'objet d'une procédure collective, soit est fermé).",
  inviteMailHint:
    "Ce mail correspond à celui de votre sous-traitant qui recevra la demande d'adhésion et les relances.",
  columns: {
    agencyCode: "Code etablissement",
    address: "Coordonnées etablissement",
    manager: "Responsable",
    edifice: "Édifice",
    inviteMail: "E-mail d'invitation",
  },
  administrativeDocuments: "Dossier administratif",
  nolegalDocuments: "Aucun document n'a été récupéré depuis e-attestation.",
};

const attachments = {
  exportBPU: "Exporter (BPU)",
  unrecordedBenefits: "Aucune prestation enregistrée",
  amountWoVat: "Montant de l'attachement HT",
  wfStatus: "Statut WF",
  cumulativePreviousAmount: "Montant des attachements précédents HT",
  cumulativeAmount: "Montant des attachements (Précédents + courant) HT",
  currentPeriodAmount: "Montant de cet attachement HT",
  completionDate: "Fin prévue le",
  totalAmount: "Montant total de la commande HT",
  observationCMD: "Observation (CMD)",
  finalBilling: "Décompte définitif",
  evaluationDate: "Évaluation",

  prestationColumns: {
    orderQuantity: "Quantité de la commande",
    orderAmount: "Montant de la commande",
    cumulativeQuantity: "Quantité des attachements (Précédents + courant)",
    cumulativeAmount: "Montant des attachements (Précédents + courant)",
    cumulativePreviousQuantity: "Quantité des attachements précédents",
    cumulativePreviousAmount: "Montant des attachements précédents",
    currentQuantity: "Quantité pour cet attachement",
    currentAmount: "Montant pour cet attachement",
    help: "Quantités de tous les attachements précédents ainsi que celles de l'attachement courant. Les quantités des attachements suivants ne sont pas prises en compte.",
    helpTwo:
      "Quantité de tous les attachements précédents, dont la date de mise en validation est antérieure à celle de l'attachement courant.",
  },

  attachmentCreation: "Création d'un attachement",
  validatedOrders: "Liste des commandes validées",
  sameMonthAttachment:
    "Attention vous avez déjà attaché cette commande ce mois-ci. Souhaitez-vous continuer ?",
  prestationSelection: "Selection des prestations",
};

const bpus = {
  bpuCreation: "Création d'un BPU",
};

const bpuTemplates = {
  bpuTemplateCreation: "Création d'un BPU Template",
};

const categories = {
  parentCategory: "Catégorie parente",
  mergeDuplicates: "Fusionner les doublons",
  categoryCreation: "Création d'une catégorie",
};

const companies = {
  agencyCreation: "Création d'un etablissement",

  companyCreation: "Création d'une société",
  columns: {
    edificeCode: "Code édifice",
  },
};

const contractTemplates = {
  addContractTemplate: "Ajout d'un modèle de contrat",
  downloadContractTemplate: "Télécharger un modèle de contrat",
  contractTemplateName: "Nom du modèle de contrat",
};

const dc4 = {
  title: "Suivi des DC4",
  attachmentsPaid: "Montant Attachements Payés",
  readyToPayAttachments: "Montant Attachements Situation Mensuelle",
  validatedAttachments: "Montant Attachements Validés",
  allAttachments: "Montant HT Attachements validés/situation mensuelle/payés",
  availableDC4: "Disponible sur DC4",
  availableDC4WithRad: "Disponible sur DC4 avec RAD",
};

const deposits = {
  depositAmount: "Montant de la caution",
  depositUsed: "Montant utilisé",
  amountLeft: "Montant restant",
  reimbursementDate: "Date de mainlevée",
  addDeposit: "Ajout d'une caution",
};

const envelopes = {
  addEnvelope: "Ajouter une enveloppe",

  validation: {
    sendToValidation: "Envoyer en validation",
    atLeast2RolesRequired: "Au moins 2 rôles requis!",
    eachRoleMustSelectAUser: "Chaque rôle doit sélectionner un utilisateur !",
    validatorSelectionModalTitle: "Selection des validateurs",
  },

  signature: {
    sendForSignature: "Envoyer en signature",
    signersModalTitle: "Coordonnées des signataires",
    axioneSide: "Axione",
    contractorSide: "Sous-traitant",
    signaturePosition: "Positionnement de la signature",
    anchorString: "Clé de positionnement",
    positionHelper:
      "En mode manuel les signataires positionnent eux-mêmes leur signature dans le document.\nEn mode automatique, la signature est positionnée grâce à une clé (chaîne de caractères).\nLes clés de positionnement doivent être de la même couleur que le fond du document afin qu'elles ne soient pas visibles dans le document final.",
    anchorStingsShouldBeDifferent:
      "Les clés de positionnement doivent être différentes",
    successfullySent: "Contrat envoyé en signature avec succès",
    failedSent: "Une erreur s'est produite lors de l'envoi en signature",
    manual: "Manuel",
    automatic: "Automatique",
    sendError: "Erreur d'envoi à la signature",
  },
};

const documentTypes = {
  documentTypeCreation: "Création d'un type de document",
};

const markets = {
  nbAttachments: "NB Attachements",
  currentBpu: "BPU en cours",
  marketCreation: "Création d'un marché",
  deactivate: "Désactiver",
  activate: "Activer",

  columns: {
    agencyCode: "Code etablissement (Nom du marché)",
    edifice: "Référence interne contrat",
    contractNumber: "Numéro de contrat",
    dateEndOfContract: "Date de fin de validité du contrat",
    eotpSt: "eOTP ST",
    marketNumber: "N° Marché SAP",
    maxAmountDC4: "Montant Max DC4 HT",
    paymentCondition: "Condition de paiement",
    paymentType: "Paiement direct/Entreprise",
    thresholdAmount: "Montant seuil",
    rgRate: "Taux RG",
    vatType: "Type de TVA",
    activated: "Actif",
  },
  paymentTypes: {
    direct: "Paiement direct",
    company: "Paiement Entreprise",
  },
  vatTypes: {
    basic: "20%",
    tenPercents: "10%",
    reverseCharge: "Autoliquidée",
  },
};

const notifications = {
  refus: "Vous devez justifier le refus.",
  min: "(5 caractères minimum).",
  title: "Titre",
  active: "Actif",
};

const ods = {
  title: "Masque d'OD",
  situationDate: "Date situation mensuelle",
  attSubcontractor: "ATT sous-traitant",
  senderEotp: "Elément eOTP émetteur",
  receiverEotp: "Elément eOTP destinataire",
  amount: "Montant € HT",
  accountingNature: "Nature Comptable",
};

const oscarSubProjects = {
  constructionBaseQuantities: "Quantités base construction",

  columns: {
    subProjectYear: "Année du sous-projet",
    subProjectCity: "Commune du sous-projet",
    subprojectStatus: "Etat du sous-projet",
    arcgisName: "Nom Arcgis",
    oscarSubProject: "Sous-projet OSCAR",
    arcgisReference: "Référence Arcgis",
    oiCode: "Code OI",
    marketType: "Type de marché",
    projectName: "Nom du projet",
    subProjectName: "Nom du sous-projet",
    nroName: "Nom du NRO",
    nroIpeId: "Id IPE NRO",
    pmIpeId: "Id IPE PM",
    pmOfferId: "ID OFFRE PM",
    pmAxioneId: "ID interne Axione du PM",
    pmCity: "Commune du PM",
  },
};

const orders = {
  releaseSituation: "Situation de libération",
  wfStatus: "Statut WF",
  displayAllPrestations: "Afficher toutes les prestations du BPU Sous-traitant",
  totalPrestation: "Total de la prestation",
  invoiced: "Déjà facturé",
  releaseDate: "Date de libération de la RG",
  needMarket: "Nécessite un marché",
  orderCreation: "Création d'une commande",

  columns: {
    edifice: "Référence interne contrat",
    contractNumber: "Numéro de contrat",
    complement: "Compléments / Détails",
    clientMarket: "Marché client",
    completionDate: "Fin prévue le",
    deliveryDuration: "Délai de réalisation",
    bdcCustomer: "BDC client",
    managedBy: "Suivi par",
    mainClient: "Client principal",
    orderManagedBy: "Affaire suivie par",
    sstMarket: "Marché SST",
    deliveryDate: "PV de réception",
  },
};

const orderTemplates = {
  switchAll: "Tout ouvrir/fermer",
  templateCreation: "Création d'un modèle de commande",
};

const paymentGuarantees = {
  fieldNamesMustBeInList:
    "Les noms des champs du formulaire doivent faire partie des noms suivants :",
  addGuarantee: "Ajout d'une garantie de paiement",
};

const prestations = {
  needCategory: "Nécessite une catégorie",
  prestationCreation: "Création d'une prestation",

  columns: {
    unitPrice: "Prix unitaire",
    itemCode: "Code article",
  },
};

const prestationTypes = {
  prestationTypeCreation: "Création d'un type de prestation",
};

const projects = {
  nbOrdersValidated: "Nb CMD Validées et/ou Clôturées",
  nbAttachmentsPaid: "Nb attachements payés",
  sumOrdersValidated: "Somme CMD Validées et/ou Clôturées",
  sumAttachmentsPaid: "Somme attachements payés",
  imputationCreation: "Création d'une imputation",
  editImputation: "Modifier une imputation",
  addUserToProject: "Ajouter un utilisateur à un projet",
  monthlyGenerationDay: "Jour de génération des situations mensuelles",
  shareFiles: "Partager les attachements dans Sharepoint",

  projectCreation: "Création d'un projet",
  directorAddress: "Adresse du responsable",
  columns: {
    manager: "Responsable",
  },
};

const projectTypes = {
  projectTypeCreation: "Création d'un type de projet",
};

const quantitatives = {
  quantityOrdered: "Quantités commandées",
  cumulatedQuantity: "Quantités réalisés en cumulé",
  orderedAmount: "Montant commandé",
  cumulativeAmount: "Montant réalisé en cumulé",
};

const roles = {
  roleCreation: "Création d'un rôle",
  additionalPermissions:
    "Vous pouvez définir un ensemble de permissions supplémentaires sur ce rôle.",
  visibilities: {
    all: "Accès à tous les éléments de la base",
    projects: "Accès limités aux projets associés à l'utilisateur",
    managerProjects:
      "Accès limités aux projets associés au responsable de l'utilisateur",
    projectsOffices:
      "Accès limités aux projets ET aux etablissements associés à l'utilisateur",
  },
};

const situations = {
  markPaid: "Marquer comme payée",
  cancelPayment: "Annuler le paiement",
  attachmentNo: "N° attachement",
  releasedAmount: "Montant RG libérée HT",
  sapReference: "Référence dans SAP",
  theoreticalPaymentDate: "Date de paiement théorique",
  currentMonthAmount: "Montant HT du mois",
  rgAmount: "Montant HT RG",
  bondAmount: "Montant cautionné",
  withheldAmount: "Montant non cautionné",
  amountWoRg: "Montant HT-RG",
  totalAmountWoVat: "Montant total HT",
  vatAmount: "Montant TVA",
  reverseChargeAmount: "Montant TVA Autoliquidée",
  totalAmount: "Montant à payer TTC",
  releaseSituation: "Situation de libération",
  releaseDate: "Date de libération de la RG",
  totalWoVat: "Total HT",
  totalWithVat: "Total TTC",
  situationCreation: "Création d'une situation mensuelle",
  projectSelection: "Selection des projets",
};

const units = {
  unitCreation: "Création d'une unité",
};

const users = {
  changePerimeter: "Changer le périmètre",
  projectType: "Type de projet",
  secondaryProfiles: "Profils secondaires",
  assignPerimeterToUser: "Affecter le périmètre à un autre utilisateur",
  authorizationAmount: "Montant d'engagement",
  mainProfile: "Profil principal",
  addUser: "Ajout d'un utilisateur",
  ldapCommonName: "LDAP CommonName",
  columns: {
    manager: "Responsable",
  },
};

const warranties = {
  title: "Suivi des RG/Cautions",
  depositType: "RG/Caution",
  attachementAmount: "Montant ATT validés",
  totalRetention: "Montant total RG à retenir",
  releasedAmount: "Montant RG libérée",
  remainingAmount: "Montant restant",
  bondAmount: "Montant cautionné",
  withheldAmount: "Montant des retenues non cautionnées",

  orderReference: "Référence commande",
  orderEndDate: "Date de fin de commande",
  deliveryDate: "Date PV de réception",
  releaseDate: "Date libération RG",
  attAmount: "Montant des ATT",
  attAmountHelp: "ATT validés + ATT Situation mensuelle + ATT Payés",
  rgHelp: "Retenue de garantie",
  bondRg: "RG cautionnée",
  bondRgHelp: "Montant utilisé sur une caution",
  withheldRg: "RG non cautionnée",
  rgReleased: "RG libérée",
  rgReleasedHelp: "RG déjà libérée dans une situation mensuelle",
  rgToRelease: "RG à libérer",
  rgToReleaseHelp: "RG restant à libérer sur une situation mensuelle",
  retentionTypes: {
    basic: "Retenue de garantie",
    withThreshold: "Retenue de garantie avec seuil",
    nonApplicable: "Non concerné",
  },
};

const errors = {
  many2OneField: {
    selectItem: "Le champ {field} est obligatoire",
  },
};

const account = {
  myAccount: "Mon compte",
  delegation: {
    delegations: "Mes délégations",
    noDelegation: "Vous n'avez pas paramètré de délégations de droits.",
    delegationCreation: "Création d'une délégation",
    delegateTo: "Délégué à",
    receivedDelegations: "Délégations reçues",
    noReceivedDelegation: "Vous n'avez reçu aucune délégations de droits.",
    delegateFrom: "Délégation de",
    userToDelegate: "Utilisateur a déléguer",
    userDelegated: "Utilisateur recevant la délégation",
    userDelegations: "Délégation de l'utilisateur",
    userNoDelegation:
      "L'utilisateur n'a pas paramètré de délégations de droits.",
    userNoReceivedDelegation:
      "L'utilisateur n'a reçu aucune délégations de droits.",
    byDelegationOf: "en délégation de",
  },
};

const contract = {
  contractNumber: "Numéro de contrat",
  parentContract: "Contrat parent",
  contractType: "Type de contrat",
  signatureDate: "Date de signature",
  startDate: "Entrée en vigueur",
  endDate: "Date d'expiration",
  duration: "Délai de préavis",
  terminationDate: "Date de résiliation",
  expirationDateAfterRenewal: "Date d'expiration après reconductions",
  referent: "Acheteur référent",
  adminReferent: "Contrat admin référent",
  active: "Actif",
  contractCreation: "Création d'un contrat",
  contractChild: "Contrats enfants",
  noChildContracts: "Aucun contrat enfants.",
};

const popup = {
  tabs: {
    content: "Contenu de la popup",
    users: "Utilisateurs",
    information: "Informations",
  },
  addUsers: "Ajouter des utilisateurs",
  removeUsers: "Supprimer des utilisateurs",
  readedAt: "Lu",
  closedAt: "Fermé",
};

const evaluation = {
  title: "Évaluation",
  evaluation: "Note",
  comment: "Commentaire",
  safetyTitle: "Évaluation sécurité",
  procurementTitle: "Évaluation achat",
  projectTitle: "Évaluation projet",
  safety: "Sécurité",
  procurement: "Achat",
  project: "Project",
  none: "",
  type: "Type",
  document: "Document",
};

export default {
  agencies,
  attachments,
  bpus,
  bpuTemplates,
  categories,
  common,
  companies,
  contractTemplates,
  dc4,
  deposits,
  documentTypes,
  entities,
  expressions,
  home,
  login,
  markets,
  notifications,
  ods,
  orders,
  orderTemplates,
  oscarSubProjects,
  paymentGuarantees,
  prestations,
  prestationTypes,
  projects,
  projectTypes,
  quantitatives,
  roles,
  situations,
  units,
  users,
  warranties,
  errors,
  account,
  contract,
  popup,
  evaluation,
  envelopes,
};
