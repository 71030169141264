<template>
  <div class="home">
    <div class="container is-fluid">
      <div class="header">
        <h1 class="title" v-if="node && node.workflow">
          {{ $t("common.workflow") }} {{ node.workflow.name }} - {{ node.name }}
        </h1>
      </div>

      <div class="level">
        <div class="level-left actions">
          <!-- hide envelope transition buttons -->
          <button
            v-for="(transition, index) in transitions"
            v-show="
              !Object.values(WorkflowCodes.ENV.TRANSITION).includes(
                transition.transitionCode
              )
            "
            :key="index"
            class="button is-success"
            :disabled="loading"
            :class="{
              'is-danger': transition.transitionType === 2,
              'is-loading': loading,
            }"
            @click="moveToken(transition)"
          >
            {{ transition.name }}
          </button>
        </div>

        <div
          v-if="hasMyActions && transitions.length != 0"
          class="field has-addons"
        >
          <p class="control">
            <a
              class="button"
              :class="{ 'is-primary': toDoFilter === 'all' }"
              @click="setToDoFilter('all')"
              >{{ $t("common.all") }}</a
            >
          </p>

          <p class="control">
            <a
              class="button"
              :class="{ 'is-primary': toDoFilter === 'canDo' }"
              @click="setToDoFilter('canDo')"
              >{{ $t("expressions.myScope") }}</a
            >
          </p>

          <p class="control">
            <a
              class="button"
              :class="{ 'is-primary': toDoFilter === 'haveToDo' }"
              @click="setToDoFilter('haveToDo')"
              >{{ $t("expressions.myTasks") }}</a
            >
          </p>
        </div>
      </div>

      <datatable
        v-if="node.objectName"
        :fetch="(p) => $api.fetchTokens(id, p, filter)"
        ref="listTokens"
        v-model="selected"
        :columns="columns"
        order="DESC"
      >
        <div
          slot="entity.total"
          slot-scope="{ item }"
          :title="$t('common.amount')"
          class="nowrap has-text-right"
        >
          {{ item.entity && item.entity.total | priceEUR }}
        </div>
        <div
          slot="entity.amount"
          slot-scope="{ item }"
          :title="$t('common.amount')"
          class="nowrap has-text-right"
        >
          {{ item.entity && item.entity.amount | priceEUR }}
        </div>
        <div
          slot="entity.project"
          slot-scope="{ item }"
          :title="$t('common.project')"
        >
          {{ item.entity && item.entity.project && item.entity.project.name }}
        </div>
        <div
          slot="comment"
          slot-scope="{ item }"
          :title="$t('common.comments')"
        >
          <input type="text" v-model.trim="comments[item.id]" />
        </div>
        <div slot="createdAt" slot-scope="{ item }" :title="$t('common.date')">
          {{ item.createdAt | datetime }} ({{ item.createdAt | fromNow }})
        </div>
        <div slot="actions" slot-scope="{ item }">
          <span v-if="item.entity">
            <router-link
              v-if="node.objectName == WorkflowCodes.OS.NODE.OBJECT_NAME"
              :to="'/sst/order/' + item.entity.id"
              class="button is-primary"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              v-if="node.objectName == WorkflowCodes.ATT.NODE.OBJECT_NAME"
              :to="'/sst/attachement/' + item.entity.id"
              class="button is-primary"
            >
              <i class="fa fa-search"></i>
            </router-link>
            <router-link
              v-if="node.objectName == WorkflowCodes.ENV.NODE.OBJECT_NAME"
              :to="
                '/sst/contract/' +
                item.entity.contractId +
                '?envelopeId=' +
                item.entity.id
              "
              class="button is-primary"
            >
              <i class="fa fa-search"></i>
            </router-link>
          </span>
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Workflows as WORKFLOWS } from "../../constants/Workflow";

export default {
  props: ["id"],
  name: "workflow-node",
  data() {
    return {
      node: {},
      transitions: {},
      tokens: [],
      loading: false,
      comments: {},
      selected: [],
      toDoFilter: "haveToDo",
      WorkflowCodes: WORKFLOWS,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    id() {
      this.fetchData();
    },
    filter() {
      this.$refs.listTokens.refresh();
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      hasMyActions: "auth/hasMyActions",
    }),
    filter() {
      if (!this.hasMyActions || this.transitions.length === 0) {
        return "all";
      }
      return this.toDoFilter;
    },
    columns() {
      if (this.node.objectName === this.WorkflowCodes.OS.NODE.OBJECT_NAME) {
        return {
          id: {
            title: "ID",
            sortable: true,
            sortBy: "id",
          },
          "entity.reference": {
            title: this.$t("common.reference"),
            filter: "",
            sortable: true,
            sortBy: "reference",
          },
          "entity.name": {
            title: this.$t("common.name"),
            filter: "",
            sortable: true,
            sortBy: "name",
          },
          "entity.clientMarket": {
            title: this.$t("orders.columns.clientMarket"),
            filter: "",
          },
          "entity.project": {
            title: this.$t("common.project"),
            filter: "",
            sortable: true,
            sortBy: "market.project.name",
          },
          "entity.endDate": {
            title: this.$t("expressions.endDate"),
            filter: "",
            sortable: true,
            sortBy: "endDate",
          },
          "entity.oscarSubProject.nroName": {
            title: this.$t("common.nro"),
            filter: "",
          },
          "entity.oscarSubProject.ipeIdPm": {
            title: this.$t("common.pm"),
            filter: "",
          },
          "entity.imputation.eotp": {
            title: this.$t("common.eotp"),
            filter: "",
            sortable: true,
            sortBy: "imputation.eotp",
          },
          "entity.follower.name": {
            title: this.$t("orders.columns.managedBy"),
            filter: "",
            sortable: true,
            sortBy: "follower.name",
          },
          "entity.validator.name": {
            title: this.$t("common.validator"),
            filter: "",
            sortable: true,
            sortBy: "validator.name",
          },
          "entity.total": {
            title: this.$t("common.amount"),
            filter: "",
            sortable: true,
            sortBy: "amount",
          },
          comment: this.$t("common.comments"),
          createdAt: {
            title: this.$t("common.date"),
            sortable: true,
            sortBy: "createdAt",
          },
          "entity.creator.name": {
            title: this.$t("common.creator"),
            filter: "",
            sortable: true,
            sortBy: "creator.name",
          },
        };
      }

      if (this.node.objectName === this.WorkflowCodes.ATT.NODE.OBJECT_NAME) {
        return {
          id: {
            title: "ID",
            sortable: true,
            sortBy: "id",
          },
          "entity.reference": {
            title: this.$t("common.reference"),
            filter: "",
            sortable: true,
            sortBy: "reference",
          },
          "entity.order.reference": {
            title: this.$t("common.order"),
            filter: "",
            sortable: true,
            sortBy: "order.reference",
          },
          "entity.order.clientMarket": {
            title: this.$t("orders.columns.clientMarket"),
            filter: "",
          },
          "entity.project": {
            title: this.$t("common.project"),
            filter: "",
            sortable: true,
            sortBy: "order.market.project.name",
          },
          "entity.imputation.eotp": {
            title: this.$t("common.imputation"),
            filter: "",
          },
          "entity.amount": {
            title: this.$t("common.amount"),
            filter: "",
            sortable: true,
            sortBy: "amount",
          },
          "entity.validator.name": {
            title: this.$t("common.validator"),
            filter: "",
            sortable: true,
            sortBy: "validator.name",
          },
          comment: this.$t("common.comments"),
          createdAt: {
            title: this.$t("common.date"),
            sortable: true,
            sortBy: "createdAt",
          },
          "entity.creator.name": {
            title: this.$t("common.creator"),
            filter: "",
            sortable: true,
            sortBy: "creator.name",
          },
        };
      }

      if (this.node.objectName === this.WorkflowCodes.ENV.NODE.OBJECT_NAME) {
        return {
          id: {
            title: "ID",
            sortable: true,
            sortBy: "id",
          },
          "entity.name": this.$t("common.name"),
          // "entity.contract.contractNumber": {
          //   title: this.$t("contract.contractNumber"),
          //   filter: "",
          //   sortable: true,
          //   sortBy: "contract.contractNumber",
          // },
          createdAt: {
            title: this.$t("common.date"),
            sortable: true,
            sortBy: "createdAt",
          },
        };
      }
      return null;
    },
  },
  methods: {
    fetchData() {
      axios.get(`/node/${this.id}`).then((response) => {
        this.node = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
      this.fetchTransitions();
    },
    fetchTransitions() {
      axios.get(`/node/${this.id}/transitions`).then((response) => {
        this.transitions = response.data;
        this.transitions.sort((a, b) => a.transitionType - b.transitionType);
      });
    },
    moveToken(transition) {
      const requests = [];
      this.loading = true;
      this.selected.forEach((node) => {
        const comment = this.comments[node.id];
        requests.push(
          axios
            .post(`/wftoken/move/${node.id}/${transition.id}`, {
              comment,
            })
            .then(() => {
              this.selected.splice(this.selected.indexOf(node.id), 1);
            })
        );
      });

      Promise.all(requests).finally(() => {
        this.loading = false;
        this.$refs.listTokens.refresh();
      });
    },
    setToDoFilter(action) {
      this.toDoFilter = action;
    },
  },
};
</script>

<style scoped>
.actions button {
  margin-right: 20px;
}
</style>
