<template>
  <div>
    <modal
      ref="downloadContractTemplateModal"
      @close="reset"
      :title="$t('contractTemplates.downloadContractTemplate')"
    >
      <form
        class="is-hidden"
        ref="downloadingForm"
        method="POST"
        target="_blank"
        :action="`${API}/contracttemplate/${documentId}`"
      >
        <input type="hidden" name="token" :value="authToken" />
      </form>
      <datatable
        :single-select="true"
        :size="15"
        :fetch="fetchContractTemplates"
        :columns="columns"
        @input="handleTemplateSelection"
        selector
        class="mb-5"
      >
      </datatable>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DownloadContractTemplateModal",
  data() {
    return {
      documentId: null,
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
    };
  },
  computed: {
    columns() {
      return { templateName: this.$t("expressions.contractName") };
    },
  },
  methods: {
    open() {
      this.reset();
      this.$openModal("downloadContractTemplateModal");
    },
    close() {
      this.$closeModal("downloadContractTemplateModal");
    },
    async fetchContractTemplates(params) {
      try {
        return await this.$api.fetchContractTemplates(
          params,
          "contractor_contract"
        );
      } catch (error) {
        console.error("Error fetching contract templates:", error);
        this.$awn.error(this.$t("expressions.fetchTemplatesError"));
      }
    },
    handleTemplateSelection(event) {
      this.documentId = event.id;
      this.$nextTick(() => {
        if (this.$refs.downloadingForm) {
          this.$refs.downloadingForm.submit();
          this.close();
        }
      });
    },
    reset() {
      this.documentId = null;
    },
  },
};
</script>
